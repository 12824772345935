import React from 'react';
import './LinkedInfo.css';
import { Container, Row, Col } from 'react-bootstrap';
import TruckImage from '../../images/viljuskar.png';
import { Link } from 'react-router-dom';

const LinkedInfo = ({ data }) => {
    return (
        <div className="linked-info-outher-container">
            <div className="linked-info-text-outher-container">
                <Container className="linked-info-text-container">                
                    <Row>
                        <Col className="linked-info-text-image linked-info-text-item d-lg-flex" lg={3} md={12} sm={12}>
                            <img src={TruckImage} alt="err"/>
                        </Col>
                        <Col className="linked-info-text-item" lg={3} md={4}>
                            <h1>{data.povrsinaSkladista}</h1>
                            <h3>m<sup>2</sup> skladišta</h3>
                        </Col>
                        <div className="vertical-line d-lg-block d-md-block d-sm-none d-xs-none"></div>
                        <Col style={{marginLeft: '-3px'}} className="linked-info-text-item linked-info-text-item-border-top" lg={3} md={4}>
                            <h1>{data.posiljakaGodisnje}</h1>
                            <h3>pošiljaka godišnje</h3>
                        </Col>
                        <div className="vertical-line d-lg-block d-md-block d-sm-none d-xs-none"></div>
                        <Col style={{marginLeft: '-3px'}} className="linked-info-text-item linked-info-text-item-border-top" lg={3} md={4}>
                            <h1>{data.transportnihVozila}</h1>
                            <h3>kupaca / kooperanata</h3>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div className="linked-info-featured-image">
                <img src={data.dodatnifooterinfoslika.sourceUrl} alt="err"/>
                <div className="linked-info-featured-image-button-container">
                    <Link to='/kontakt' onClick={e => window.scrollTo(0, 0)}>NAZOVI KNAPUT I KRENI NA PUT</Link>
                </div>
            </div>
        </div>
    )
}

export default LinkedInfo;