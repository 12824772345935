import React, { useState } from 'react';
import './footer.css';
import FooterIcon from '../../../images/Ikona-02.png';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';

const Footer = ({ popupData }) => {

    const [modalShow, setModalShow] = useState(false);

    return (
        <div className="footer-outher-container">
            <div className="footer-info-container">
                <div className="working-hours-container">
                    <img src={FooterIcon} alt="err"/>
                    <p>Radno vrijeme</p>
                    <p className="fotter-shadow-left">Pon-Pet: <span className="red-color-text">8.00-16.00</span></p>
                    {/* <p className="fotter-shadow-left">Sub: <span className="red-color-text">7.00-13.00</span></p> */}
                </div>
                <div>
                    <Link to="/kontakt" onClick={e => window.scrollTo(0, 0)}>Kontakt</Link>
                </div>
                <div className="footer-small-text">
                    <p>Knaput logistika d.o.o. 2020.</p>
                    <p className="fotter-shadow-left footer-popup-trigger" onClick={e => setModalShow(true)}>Uvijeti korištenja</p>
                </div>
            </div>
            <Modal show={modalShow} onHide={e => setModalShow(false)} className="footer-popup-container">
                <Modal.Header closeButton>
                <Modal.Title className="blog-header"><h1>{popupData.title}</h1></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                {popupData.blocks.map((val, i) => {
                    if(i === 0){
                        return <div className="blog-header-blocks" dangerouslySetInnerHTML={{__html: `${val.originalContent}`}}></div>
                    }else if(val.name.includes('heading')){
                        return <div className="blog-header-blocks" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                    }else if(val.name.includes('paragraph')){
                        return <div className="blog-paragraph-blocks" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                    }else if(val.name.includes('list')){
                        return <ul className="blog-list-blocks popup-list-block" dangerouslySetInnerHTML={{__html: val.attributes.values}}></ul>
                    }else if(val.name.includes('image')){
                    }
                })}
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default Footer;