import React, { useState } from 'react';
import './contact.css';
import { Container, Row, Col } from 'react-bootstrap';
import SkladisteIcon from '../../images/Ikona-07.png';
import KucicaIcon from '../../images/Ikona-08.png';
import BackgroungImage from '../../images/Kamion-bck.jpg';
import { withScriptjs, withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import * as EmailValidator from 'email-validator';
import ReCAPTCHA from 'react-google-recaptcha';
import axios from 'axios';
import { X } from 'react-bootstrap-icons';
import Seo from '../../components/seo';

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={13}
    defaultCenter={{ lat: 46.298438, lng: 16.350937 }}
  >
    {props.isMarkerShown && <Marker position={{ lat: 46.298438, lng: 16.350937 }} />}
  </GoogleMap>
))

const Contact = ({ data }) => {
    const [formData, setFormData] = useState({name: '', email: '', poruka: ''});
    const [formError, setFormError] = useState({name: {class: '', outline: ''}, email: {class: '', outline: ''}, poruka: {class: '', outline: ''}});
    const [notRobot, setNotRobot] = useState();
    const [recaptchaError, setRecaptchaError] = useState({class: '', ref: {}});
    const [formSentMessage, setFormSetMessage] = useState('message-hidden');
    const [contactMessage, setContactMessage] = useState('Poruka je poslana');

    const onSubmit = (e) => {
        e.preventDefault();
        let submitNotValid = false;
        let errorData = formError;
        if(formData.name.length < 2){
            errorData = {...errorData, name: {class: 'error-message-display', outline: 'input-error-outline'}};
            submitNotValid = true;
        }
        if(!EmailValidator.validate(formData.email)){
            errorData = {...errorData, email: {class: 'error-message-display', outline: 'input-error-outline'}};
            submitNotValid = true;
        }
        if(formData.poruka.length === 0){
            errorData = {...errorData, poruka: {class: 'error-message-display', outline: 'input-error-outline'}};
            submitNotValid = true;
        }
        if(submitNotValid){
            setFormError(errorData);
            return
        }
        if(notRobot === undefined){
            setRecaptchaError({...recaptchaError, class: 'error-message-display'});
            return
        }
        recaptchaError.ref.current.props.grecaptcha.reset();
        sendingEmail(formData);
        setFormData({name: '', email: '', poruka: ''});
    }

    const sendingEmail = async (data) => {
        try{
            let requestResponse = await axios.post('https://knaput-admin.kreda.hr/wp-json/email/send', JSON.stringify(data)
            ,{
                headers: {'Content-Type': 'application/json'}
            });
            if(requestResponse.request.status === 200){
                setFormSetMessage('');
                setContactMessage('Poruka je poslana');
            }else{
                setFormSetMessage('message-invalid');
                setContactMessage('Poruka nije poslana, pokušajte ponovno kasnije');
            }
        } catch(err){
        }
    }

    const changingRecaptcha = (res) => {
        setNotRobot(res)
    }

    return (
        <div className="contact-outher-container">
            <Seo title={data.seo.title} description={data.seo.metaDesc} link='https://knaputlogistika.hr/kontakt/'/>
            <img className="blog-backgroung-image" src={BackgroungImage} alt="err"/>
            {data !== undefined ? (
            <>
            <div className="blog-baner-outher-container">
                <div className="baner-shadow-container">
                    <div className="baner-shadow-left"></div>
                    <div className="baner-shadow-bottom"></div>
                    <div className="baner-shadow-right"></div>
                </div>
                <img src={data.uslugaBaner.uslugeBanerSlika.sourceUrl} alt="err"/>
            </div>
            <div className="blog-baner-title-container">
                <h3 className="blog-baner-title">Kontakt</h3>
            </div>
            <Container className="contact-container">
                <Row>
                    <Col lg={6} md={12}>
                        <h3 className="blog-header mg-top-40">Knaput logistika d.o.o.</h3>
                        <div className="contact-text-container">
                            <img src={SkladisteIcon} alt="err"/>
                            <h4 className="contact-small-title">SKLADIŠTE</h4>
                            <p className="blog-paragraph">{data.skladiste.adresa}</p>
                            <MyMapComponent
                            isMarkerShown
                            googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyB6h1LA0D2FJy9Q3jwOLCDpY-0NKGQXEdU"
                            loadingElement={<div style={{ height: `100%` }} />}
                            containerElement={<div className="google-maps-container" />}
                            mapElement={<div style={{ height: `100%` }} />}
                            />
                        </div>
                        <div className="contact-text-container">
                            <img src={KucicaIcon} alt="err"/>
                            <h4 className="contact-small-title">SJEDIŠTE</h4>
                            <div className="blog-paragraph-blocks sjediste-text" dangerouslySetInnerHTML={{__html: data.sjediste.mainInfo}}></div>
                            <p className="blog-paragraph podaci-o-firmi-text" dangerouslySetInnerHTML={{__html: data.sjediste.podaciOFirmi.split('\r').join('<br>')}}></p>
                        </div>
                    </Col>
                    <Col lg={6} md={12} className="kontaktiraj-nas-container">
                        <h3 className="blog-header mg-top-40-bottom-title">Kontaktirajte nas</h3>
                        <form className="form-container" onSubmit={e => onSubmit(e)}>
                            <label>Ime</label>
                            <input className={formError.name.outline} value={formData.name} onChange={e => {setFormData({...formData, name: e.target.value}); setFormError({...formError, name: {class: '', outline: ''}})}}/>
                            <p className={`input-error-message ${formError.name.class}`}>Ime je neispravno</p>
                            <label>Vaš e-mail</label>
                            <input className={formError.email.outline} value={formData.email} onChange={e => {setFormData({...formData, email: e.target.value}); setFormError({...formError, email: {class: '', outline: ''}})}}/>
                            <p className={`input-error-message ${formError.email.class}`}>Email je neispravan</p>
                            <label>Poruka</label>
                            <textarea className={formError.poruka.outline} type='textarea' value={formData.poruka} onChange={e => {setFormData({...formData, poruka: e.target.value}); setFormError({...formError, poruka: {class: '', outline: ''}})}}/>
                            <p className={`input-error-message ${formError.poruka.class}`}>Poruka je neispravna</p>
                            <ReCAPTCHA ref={recaptchaError.ref} sitekey='6LdsNR4aAAAAAFfuE4sxIRxcwv5COYL25ANMcSjN' onChange={changingRecaptcha}/>
                            <p className={`input-error-message ${recaptchaError.class}`}>Potvrdite da niste robot</p>
                            <button className="form-button">Pošalji upit</button>
                        </form>
                    </Col>
                </Row>
            </Container>
            </>
            ) : null}
            <div className={`message-sent-container ${formSentMessage}`}>
                <p>{contactMessage}</p>
                <div onClick={e => {setFormSetMessage('message-hidden'); setContactMessage('Poruka je poslana');}}><X fontSize="25px"/></div>
            </div>
        </div>
    )
}

export default Contact;