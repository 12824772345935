import React, { useState } from 'react';
import './usluga.css';
import { Link } from 'react-router-dom';

const Usluge = ({ data }) => {
    const [icon, setIcon] = useState(data.landinguslugaikona.sourceUrl);
    return(
        <div className="landing-usluga-outher-container">
            <div className="usluga-background-outher-stripe">
                <div className="usluga-background-inner-stripe"></div>
            </div>
            <Link to={data.landinguslugapoveznica[0].uri} className='usluga-blob-link' onClick={e => window.scrollTo(0, 0)} onMouseEnter={e => setIcon(data.ikonaSvijetla.sourceUrl)} onMouseLeave={e => setIcon(data.landinguslugaikona.sourceUrl)}>
                <div className="usluga-blob-container">
                    <h3 className="usluga-blob-title">{data.landinguslugapoveznica[0].title}</h3>
                    <div className="usluga-blob-icon-container">
                    </div>
                    <img className="usluga-blob-icon" src={icon} alt="err"/>
                    <div className="usluga-blog-hover"></div>
                </div>
            </Link>
        </div>
    )
}

export default Usluge;