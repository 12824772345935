import React from 'react';
import './LandingBaner.css';
import LandingVideo from '../../video/Knaput-video-NOVO.mp4';
import ReactPlayer from 'react-player';
import Logo from '../../images/LOGO.png';

const LandingBaner = ({ data }) => (
    <div className="landing-baner-outher-container">
        <div className="baner-shadow-container">
            <div className="baner-shadow-left"></div>
            <div className="baner-shadow-bottom"></div>
            <div className="baner-shadow-right"></div>
        </div>
        <div className="video-container">
            <ReactPlayer playing={true} muted loop={true} url={LandingVideo} />
        </div>
        <div className="landing-baner-text-container">
            {/* <h1 className="landing-baner-text-logo">Knaput logistika <span>d.o.o.</span></h1> */}
            <img className="landing-baner-logo"src={Logo} alt="err"/>
            <h2 className="landing-baner-text" dangerouslySetInnerHTML={{__html: data.landingbanertext.split('\r').join('<br>')}}></h2>
        </div>
    </div>
)

export default LandingBaner;