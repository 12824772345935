import React, { useEffect } from 'react';
import './blog.css';
import { Container, Row, Col } from 'react-bootstrap';
import LinkedInfo from '../../components/LinkedInfo/LinkedInfo';
import BackgroungImage from '../../images/Kamion-bck.jpg';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Seo from '../../components/seo';

const Blog = ({ data, banerTitle, linkedInfoData }) => {

    const history = useHistory();

    useEffect(() => {
        let links = document.querySelectorAll('.blog-paragraph-blocks a');
        links.forEach(val => {
            val.addEventListener('click', e => {
                e.preventDefault();
                window.scrollTo(0, 0);
                history.push(val.attributes.href.nodeValue);
            })
        })
        return () => {
            links.forEach(val => {
                val.removeEventListener('click', e => {
                    e.preventDefault();
                });
            })
        }
    }, [data])

    const redirect = (e, path) => {
        e.preventDefault();
    }

    return (
        <div className="blog-outher-container">
            <Seo title={data.seo.title} description={data.seo.metaDesc} link={`https://knaputlogistika.hr${data.uri}`}/>
            <img className="blog-backgroung-image" src={BackgroungImage} alt="err"/>
            <div className="blog-baner-outher-container d-xs-none d-sm-flex">
                <div className="baner-shadow-container">
                    <div className="baner-shadow-left-baner"></div>
                    <div className="baner-shadow-bottom-baner"></div>
                    <div className="baner-shadow-right-baner"></div>
                </div>
                <img src={data.uslugaBaner.uslugeBanerSlika.sourceUrl} alt="err"/>
            </div>
            <div className="blog-baner-title-container">
                <h3 className="blog-baner-title">{banerTitle}</h3>
            </div>
            <Container>
                <Row>
                    <Col className="blog-featured-image-container" lg={3} md={3} sm={12} xs={12}>
                        <div className="blog-featured-image">
                            <img src={data.naslovnaSlika.uslugaNaslovnaSlika.sourceUrl} alt="err" />
                        </div>
                        <div className="blog-featured-image-outher-stripe">
                            <div className="blog-featured-image-inner-stripe"></div>
                        </div>
                    </Col>
                    <Col className="blog-text-container" lg={9} md={9} sm={12} xs={12}>
                        {data.blocks.map((val, i) => {
                            if(i === 0){
                                return <div className="blog-text-first-heading blog-header-blocks" dangerouslySetInnerHTML={{__html: `<img src=${data.ikona.uslugaBlogIkona.sourceUrl} alt='err'/> ${val.originalContent}`}}></div>
                            }else if(val.name.includes('heading')){
                                if(i >= data.blocks.length - 3){
                                    return <div className="blog-header-blocks blog-text-last-block-header" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                                }
                                return <div className="blog-header-blocks" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                            }else if(val.name.includes('paragraph')){
                                if(i > data.blocks.length - 3){
                                    return <div className="blog-paragraph-blocks text-align-center-blocks blog-text-last-block" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                                }
                                return <div className="blog-paragraph-blocks" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                            }
                            else if(val.name.includes('list')){
                                return <ul className="blog-list-blocks" dangerouslySetInnerHTML={{__html: val.attributes.values}}></ul>
                            }
                            else if(val.name.includes('button')){
                                return <div className="blog-button-blocks"><Link href="#" onClick={e => window.scrollTo(0, 0)} to={val.innerBlocks[0].attributes.url}>{val.innerBlocks[0].attributes.text}</Link></div>
                            }
                            else if(val.name.includes('image')){
                                console.log('we got image')
                            }
                        })}
                    </Col>
                </Row>
            </Container>
            <LinkedInfo data={linkedInfoData}/>
        </div>
    )
}

export default Blog;