import React from 'react';
import './LandingUsluge.css';
import Usluga from './usluga';
import { Container, Row, Col } from 'react-bootstrap';

const LandingUsluge = ({ data }) => {
    return(
        <Container className="landing-usluge-outher-container">
            <div className="usluga-background-outher-stripe bg-outher-stripe d-lg-flex d-md-flex d-sm-flex d-xs-none">
                <div className="usluga-background-inner-stripe"></div>
            </div>
            <div className="usluga-background-outher-stripe bg-outher-stripe-bottom d-lg-none d-md-flex d-sm-flex d-xs-none">
                <div className="usluga-background-inner-stripe"></div>
            </div>
            <Row>
                {data.landingusluge.map((val, i) => (
                    <Col lg={3} md={6} sm={6} xs={12}>
                        <Usluga key={i} data={val}/>
                    </Col>
                ))}
            </Row>
        </Container>
    )
}

export default LandingUsluge;