import React, { useEffect, useState } from 'react';
import './App.css';
import './general.css';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import Landing from './pages/landing/landing.js';
import Layout from './components/layout/layout.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';
import Blog from './pages/blog/blog';
import Contact from './pages/contact/contact';
import Spinner from 'react-spinner-material';

function App() {
	const [navbarData, setNavbarData] = useState();
	const [landingData, setLandingData] = useState();
	const [uslugeData, setUslugeData] = useState();
	const [contactData, setContactData] = useState();
	const [privacyPolicyData, setPrivacyPolicyData] = useState();
	const [aboutData, setAboutData] = useState();

	const fetchingData = async () => {
		try {
			let data = await axios.post(
				'https://knaput-admin.kreda.hr/graphql',
				{
					query: `
            query MyQuery {
              menu(id: "dGVybToxMA==") {
                menuItems {
                  nodes {
                    path
                    childItems {
                      nodes {
                        path
                        label
                      }
                    }
                    label
                    parentId
                  }
                }
              }
              usluge(where: {language: HR}) {
                nodes {
                  seo {
                    title
                    metaDesc
                  }
                  title
                  uri
                  naslovnaSlika {
                    uslugaNaslovnaSlika {
                      sourceUrl
                    }
                  }
                  uslugaBaner {
                    uslugeBanerSlika {
                      title
                      sourceUrl
                    }
                  }
                  blocks {
                    ... on CoreParagraphBlock {
                      originalContent
                      name
                    }
                    ... on CoreImageBlock {
                      name
                      attributes {
                        ... on CoreImageBlockAttributes {
                          url
                          href
                        }
                      }
                    }
                    ... on CoreHeadingBlock {
                      originalContent
                      name
                    }
                    ... on CoreButtonsBlock {
                      originalContent
                      name
                      innerBlocks {
                        name
                        ... on CoreButtonBlock {
                          attributes {
                            ... on CoreButtonBlockAttributes {
                              url
                              text
                            }
                          }
                        }
                      }
                    }
                    ... on CoreListBlock {
                      name
                      attributes {
                        ... on CoreListBlockAttributes {
                          values
                        }
                      }
                    }
                  }
                  ikona {
                    uslugaBlogIkona {
                      sourceUrl
                    }
                  }
                }
              }
              pages(where: {language: HR}) {
                nodes {
                  seo {
                    title
                    metaDesc
                  }
                  title
                  uri
                  naslovnaSlika {
                    uslugaNaslovnaSlika {
                      sourceUrl
                    }
                  }
                  uslugaBaner {
                    uslugeBanerSlika {
                      title
                      sourceUrl
                    }
                  }
                  blocks {
                    ... on CoreParagraphBlock {
                      originalContent
                      name
                    }
                    ... on CoreImageBlock {
                      name
                      attributes {
                        ... on CoreImageBlockAttributes {
                          url
                          href
                        }
                      }
                    }
                    ... on CoreHeadingBlock {
                      originalContent
                      name
                    }
                    ... on CoreButtonsBlock {
                      originalContent
                      name
                      innerBlocks {
                        name
                        ... on CoreButtonBlock {
                          attributes {
                            ... on CoreButtonBlockAttributes {
                              url
                              text
                            }
                          }
                        }
                      }
                    }
                    ... on CoreListBlock {
                      name
                      attributes {
                        ... on CoreListBlockAttributes {
                          values
                        }
                      }
                    }
                  }
                  ikona {
                    uslugaBlogIkona {
                      sourceUrl
                    }
                  }
                  uslugaBaner{
                    uslugeBanerSlika{
                      sourceUrl
                    }
                  }
                  landingBanerText {
                    landingbanertext
                  }
                  landingUsluge {
                    landingusluge {
                      landinguslugapoveznica {
                        ... on Usluga {
                          title
                          uri
                        }
                      }
                      ikonaSvijetla {
                        sourceUrl
                      }
                      landinguslugaikona {
                        sourceUrl
                      }
                    }
                  }
                  blocks {
                    ... on CoreParagraphBlock {
                      originalContent
                      name
                    }
                    ... on CoreHeadingBlock {
                      originalContent
                      name
                    }
                  }
                  dodatniFooterInfo {
                    posiljakaGodisnje
                    povrsinaSkladista
                    transportnihVozila
                    dodatnifooterinfoslika {
                      sourceUrl
                    }
                  }
                  title
                  sjediste {
                    mainInfo
                    podaciOFirmi
                  }
                  skladiste {
                    adresa
                    googleMapsKordinate {
                      latitude
                      longitude
                    }
                  }
                }
              }
            }                                           
            `,
				},
				{
					headers: { 'Content-Type': 'application/json' },
				}
			);
			console.log(data);
			setPrivacyPolicyData(data.data.data.pages.nodes[0]);
			setNavbarData(data.data.data.menu.menuItems.nodes);
			setLandingData(data.data.data.pages.nodes[2]);
			setUslugeData(data.data.data.usluge.nodes);
			setContactData(data.data.data.pages.nodes[3]);
			setAboutData(data.data.data.pages.nodes[1]);
		} catch (err) {
			console.log(err, 'err');
		}
	};

	useEffect(() => {
		fetchingData();
	}, []);

	return (
		<div>
			<Router>
				{aboutData !== undefined ? (
					<Layout navbarData={navbarData} popupData={privacyPolicyData}>
						<Switch>
							<Route exact path='/'>
								<Landing data={landingData} />
							</Route>
							<Route path='/kontakt'>
								<Contact data={contactData} />
							</Route>
							<Route path='/o-nama'>
								<Blog data={aboutData} banerTitle='O nama' linkedInfoData={landingData.dodatniFooterInfo} />
							</Route>
							{uslugeData.map((val, i) => (
								<Route path={val.uri}>
									<Blog data={val} banerTitle='Usluge' linkedInfoData={landingData.dodatniFooterInfo} />
								</Route>
							))}
							<Route path='/*'>
								<Redirect to='/' />
							</Route>
						</Switch>
					</Layout>
				) : (
					<div className='spinner-container'>
						<Spinner size={500} spinnerColor={'#E50046'} spinnerWidth={40} visible={true} />
					</div>
				)}
			</Router>
		</div>
	);
}

export default App;
