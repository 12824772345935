import React from 'react';
import LandingBaner from '../../components/LandingBaner/LandingBaner';
import LandingUsluge from '../../components/LandingUsluge/LandingUsluge';
import './landing.css';
import LinkedInfo from '../../components/LinkedInfo/LinkedInfo';
import Seo from '../../components/seo';

const Landing = ({ data }) => {
    console.log(data.seo)
    return (
        <div>
            {data !== undefined ? (
                <>
                    <Seo title="Knaput logistika" description={data.seo.metaDesc} link='https://knaputlogistika.hr'/>
                    <LandingBaner data={data.landingBanerText}/>
                    <LandingUsluge data={data.landingUsluge}/>
                    <div className="landing-blog-text-container">
                        {data.blocks.map(val => {
                            if(val.name.includes('paragraph')){
                                return <div className="blog-paragraph-blocks text-align-center-blocks" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                            }else if(val.name.includes('heading')){
                                return <div className="blog-header-blocks text-align-center-blocks" dangerouslySetInnerHTML={{__html: val.originalContent}}></div>
                            }
                        })}
                    </div>
                    <LinkedInfo data={data.dodatniFooterInfo}/>
                </>
            ) : null}
        </div>
    )
}

export default Landing;