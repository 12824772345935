import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import './navbar.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import NavbarLogo from '../../../images/KnaputLogistika_logo.png';
import NavbarContactIcon from '../../../images/Ikona-01.png';
import KnaputLogo from '../../../images/KnaputLogistika_logo_v2.png';
import EngFlag from '../../../images/eng-flag.png';
import HrFlag from '../../../images/Flag_of_Croatia.png';

const MainNavbar = ({ data }) => {
	const [showDropdown, setShowDropdown] = useState(false);
	// const [pointer, setPointer] = useState();

	const history = useHistory();
	const redirect = (e, path) => {
		e.preventDefault();
		window.scrollTo(0, 0);
		history.push(path);
	};

	return (
		<>
			{data !== undefined ? (
				<>
					<div className='navbar-bg'></div>
					<div className='navbar-outher-container'>
						<div className='navbar-container'>
							<Navbar collapseOnSelect expand='lg' className='navbar-navigation-container'>
								<Navbar.Toggle aria-controls='basic-navbar-nav' />
								<Navbar.Collapse id='basic-navbar-nav'>
									<Nav className='mr-auto'>
										{data.map((val, i) => {
											if (val.parentId === null && val.childItems.nodes.length === 0) {
												if (i !== 0) {
													return (
														<Nav.Link className='navbar-border-left' onClick={e => redirect(e, val.path)} href='#'>
															{val.label}
														</Nav.Link>
													);
												}
												return (
													<Nav.Link onClick={e => redirect(e, val.path)} href='#'>
														{val.label}
													</Nav.Link>
												);
											} else if (val.parentId === null) {
												return (
													<NavDropdown
														className='navbar-border-left'
														title={val.label}
														id='basic-nav-dropdown'
														show={showDropdown}
														onMouseEnter={e => setShowDropdown(true)}
														onMouseLeave={e => setShowDropdown(false)}
														onClick={e => setShowDropdown(!showDropdown)}>
														{val.childItems.nodes.map((dropItem, i) => {
															return (
																<NavDropdown.Item key={i} href='#' onClick={e => redirect(e, dropItem.path)}>
																	{dropItem.label}
																</NavDropdown.Item>
															);
														})}
													</NavDropdown>
												);
											}
										})}
									</Nav>
								</Navbar.Collapse>
							</Navbar>
							<div className='navbar-logo'>
								<img src={NavbarLogo} alt='err' onClick={e => redirect(e, '/')} />
								<div className='nav-logo-overlay-eraser'></div>
								<div className='nav-logo-border'></div>
							</div>

							<div className='navbar-right-info-container'>
								{/* <div style={{paddingLeft: '20px'}} className="d-lg-block d-md-none d-sm-none d-xs-none">
                                <img src={KnaputLogo} alt="err"/>
                            </div> */}
								<div className='flags'>
									<a href='https://en.knaputlogistika.hr' className='flag'>
										<img src={EngFlag} alt='eng flag' />
									</a>
									<div className='flag'>
										<img src={HrFlag} alt='eng flag' />
									</div>
								</div>
								<div className='navbar-right-info-inner-container'>
									<div className='navbar-right-info-icon'>
										<img src={NavbarContactIcon} alt='err' />
									</div>
									<div className='navbar-right-info'>
										<p>
											t. <span className='navbar-right-info-highlighted'>042 421 526</span>
										</p>
										<p>
											e. <span className='navbar-right-info-highlighted'>info@knaputlogistika.hr</span>
										</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</>
			) : null}
		</>
	);
};

export default MainNavbar;
