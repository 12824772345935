import React from 'react';
import MainNavbar from './navbar/navbar';
import Footer from './footer/footer';
import './layout.css';

const Layout = (props) => {
    return (
        <div className="main-outer-container">
            <MainNavbar data={props.navbarData}/>
            {props.children}
            <Footer popupData={props.popupData}/>
        </div>
    )
}

export default Layout;