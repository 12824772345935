import React from 'react';
import { Helmet } from 'react-helmet';

const Seo = ({ title, description, link }) => (
    <Helmet>
        <title>{title}</title>
        <meta
            name="description"
            content={description}
        />
        <link rel="canonical" href={link} />
    </Helmet>
)

export default Seo;